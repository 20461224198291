import axios from "axios";
import { nodeUrl } from "constant"

const getFuelTrackerFilter = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}fuel-tracker-filters?${userData?.filter}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getFuelConsumptioEmission = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}fuel-consumption-emissions?countryId=${userData?.countryId}&sectorId=${userData?.sectorId}&buId=${userData?.buId}&year=${userData?.year}&qauter=${userData?.quarter}&toggle=${userData?.toggle}&graph=${userData?.graph}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getFuelConsumptioByFuelType = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}fuel-consumption-percentage?countryId=${userData?.countryId}&sectorId=${userData?.sectorId}&buId=${userData?.buId}&year=${userData?.year}&qauter=${userData?.quarter}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getGhgByBusinessUnitType = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}ghg-by-business-unit?countryId=${userData?.countryId}&sectorId=${userData?.sectorId}&buId=${userData?.buId}&year=${userData?.year}&qauter=${userData?.quarter}&page_size=${userData?.pageSize}&page=${userData?.page}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

// Service object that exposes functions
const fuelReportService = {
  getFuelTrackerFilter,
  getFuelConsumptioEmission,
  getFuelConsumptioByFuelType,
  getGhgByBusinessUnitType
};

// Export the service object
export default fuelReportService;
