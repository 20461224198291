import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fuelReportService from "./fuelReportService";
import { getErrorMessage, isCancelRequest } from "utils";
import { FuelReportInterface } from "./fuelReportInterface";

// Define the shape of the state

const initialState: FuelReportInterface = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isLoadingFilter: false,
  countriesListData : null,
  sectorListData: null,
  businessUnitListData: null,
  yearRangeData: null,
  isLoadingFuelTrackerConsumption: false,
  fuelTrackerConsumptionData: null,
  isLoadingFuelTrackerEmission: false,
  fuelTrackerEmissionData: null,
  isLoadingFuelConsumptioByFuel: false,
  fuelConsumptioByFuelData: null,
  isLoadingGhgByBusinessUnit:false,
  ghgByBusinessUnitData: null
};

// Async Thunks for fetching sustain service data
export const getCountriesListData = createAsyncThunk("get/scope1/fuel/tracker/filter/county",
  async (userData: any, thunkApi) => {
    try {
      return await fuelReportService.getFuelTrackerFilter(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getSectorListData = createAsyncThunk("get/scope1/fuel/tracker/filter/sector",
  async (userData: any, thunkApi) => {
    try {
      return await fuelReportService.getFuelTrackerFilter(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getBusinessUnitListData = createAsyncThunk("get/scope1/fuel/tracker/filter/business/unit",
  async (userData: any, thunkApi) => {
    try {
      return await fuelReportService.getFuelTrackerFilter(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getFuelTrackerConsumptionData = createAsyncThunk("get/scope1/fuel/tracker/consumption",
  async (userData: any, thunkApi) => {
    try {
      return await fuelReportService.getFuelConsumptioEmission(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getFuelTrackerEmissionData = createAsyncThunk("get/scope1/fuel/tracker/emissions",
  async (userData: any, thunkApi) => {
    try {
      return await fuelReportService.getFuelConsumptioEmission(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getFuelConsumptioByFuel = createAsyncThunk("get/scope1/fuel/tracker/consumption/by/fuel",
  async (userData: any, thunkApi) => {
    try {
      return await fuelReportService.getFuelConsumptioByFuelType(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getGhgByBusinessUnit = createAsyncThunk("get/scope1/fuel/tracker/consumption/by/business/unit",
  async (userData: any, thunkApi) => {
    try {
      return await fuelReportService.getGhgByBusinessUnitType(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const scopeOneFuelReportReducer = createSlice({
  name: "scope-one-fuel-report",
  initialState,
  reducers: {
    resetScopeOneFuelReport: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCountriesListData.pending, (state) => {
        state.isLoadingFilter = true;
        state.countriesListData = null
      })
      .addCase(getCountriesListData.fulfilled, (state, action) => {
        state.isLoadingFilter = false;
        state.countriesListData = action.payload;
        state.yearRangeData = action.payload?.data?.yearRange || null
      })
      .addCase(getCountriesListData.rejected, (state, action) => {
        state.isLoadingFilter = isCancelRequest(action?.payload);
        state.countriesListData = null
      })
      .addCase(getSectorListData.pending, (state) => {
        state.isLoadingFilter = true;
        state.sectorListData = null
      })
      .addCase(getSectorListData.fulfilled, (state, action) => {
        state.isLoadingFilter = false;
        state.sectorListData = action.payload;
        state.yearRangeData = action.payload?.data?.yearRange || null
      })
      .addCase(getSectorListData.rejected, (state, action) => {
        state.isLoadingFilter = isCancelRequest(action?.payload);
        state.sectorListData = null
      })
      .addCase(getBusinessUnitListData.pending, (state) => {
        state.isLoadingFilter = true;
        state.businessUnitListData = null
      })
      .addCase(getBusinessUnitListData.fulfilled, (state, action) => {
        state.isLoadingFilter = false;
        state.businessUnitListData = action.payload;
        state.yearRangeData = action.payload?.data?.yearRange || null
      })
      .addCase(getBusinessUnitListData.rejected, (state, action) => {
        state.isLoadingFilter = isCancelRequest(action?.payload);
        state.businessUnitListData = null
      })
      .addCase(getFuelTrackerConsumptionData.pending, (state) => {
        state.isLoadingFuelTrackerConsumption = true;
        state.fuelTrackerConsumptionData = null
      })
      .addCase(getFuelTrackerConsumptionData.fulfilled, (state, action) => {
        state.isLoadingFuelTrackerConsumption = false;
        state.fuelTrackerConsumptionData = action.payload
      })
      .addCase(getFuelTrackerConsumptionData.rejected, (state, action) => {
        state.isLoadingFuelTrackerConsumption = isCancelRequest(action?.payload);
        state.fuelTrackerConsumptionData = null
      })
      .addCase(getFuelTrackerEmissionData.pending, (state) => {
        state.isLoadingFuelTrackerEmission = true;
        state.fuelTrackerEmissionData = null
      })
      .addCase(getFuelTrackerEmissionData.fulfilled, (state, action) => {
        state.isLoadingFuelTrackerEmission = false;
        state.fuelTrackerEmissionData = action.payload
      })
      .addCase(getFuelTrackerEmissionData.rejected, (state, action) => {
        state.isLoadingFuelTrackerEmission = isCancelRequest(action?.payload);
        state.fuelTrackerEmissionData = null
      })
      .addCase(getFuelConsumptioByFuel.pending, (state) => {
        state.isLoadingFuelConsumptioByFuel = true;
        state.fuelConsumptioByFuelData = null
      })
      .addCase(getFuelConsumptioByFuel.fulfilled, (state, action) => {
        state.isLoadingFuelConsumptioByFuel = false;
        state.fuelConsumptioByFuelData = action.payload
      })
      .addCase(getFuelConsumptioByFuel.rejected, (state, action) => {
        state.isLoadingFuelConsumptioByFuel = isCancelRequest(action?.payload);
        state.fuelConsumptioByFuelData = null
      })
      .addCase(getGhgByBusinessUnit.pending, (state) => {
        state.isLoadingGhgByBusinessUnit = true;
        state.ghgByBusinessUnitData = null
      })
      .addCase(getGhgByBusinessUnit.fulfilled, (state, action) => {
        state.isLoadingGhgByBusinessUnit = false;
        state.ghgByBusinessUnitData = action.payload
      })
      .addCase(getGhgByBusinessUnit.rejected, (state, action) => {
        state.isLoadingGhgByBusinessUnit = isCancelRequest(action?.payload);
        state.ghgByBusinessUnitData = null
      })
  },
});

export const { resetScopeOneFuelReport } = scopeOneFuelReportReducer.actions;
export default scopeOneFuelReportReducer.reducer;